import React, { useState, useEffect } from 'react';
import {CommonService} from '../../../../services/common-services';

import './SearchCard.css';

const SearchCard = ({ searchQuery, setSearchQuery, currentSearchPage, setCurrentSearchPage, blogsPerPage, setBlogsData}) => {
  
  // page number
  const handlePage = (page_cmd) => {
    const prev_page = currentSearchPage;
    if (page_cmd === "prev") {
      if (prev_page > 1) {
        setCurrentSearchPage(prev_page - 1);
        getAllSearchBlogs(prev_page - 1);
      }
    }
    if (page_cmd === "next") {
      setCurrentSearchPage(prev_page + 1);
      getAllSearchBlogs(prev_page + 1);
    }
  }

  const handleSearch = () => {
    setCurrentSearchPage(1);
    getAllSearchBlogs(1);
  }

  const getAllSearchBlogs = async (currentPage) => {
    // setCurrentSearchPage(1);
    const blogs = await CommonService.getAllSearchBlogs({
      'search_query': searchQuery,
      'page_limit': blogsPerPage,
      'page_number': currentPage
    });
    setBlogsData(blogs.allBlogs);
  };

  return (
  <nav className="search-nav flex-row justify-center align-center gap-1">
      <div className='input-item'>
          <div>
            <input 
              type='text'
              className='width-300'
              placeholder='Search here'
              value={searchQuery}
              onChange={(e)=>setSearchQuery(e.target.value)}/>
          </div>
        </div>
      <div className='button button-style3' onClick={() => handleSearch()}>Search</div>
      <div className='button button-style3' onClick={() => handlePage("prev")}>Prev</div>
      <div className='button button-style3' onClick={() => handlePage("next")}>Next</div>
    </nav>
  )
};

export default SearchCard;
