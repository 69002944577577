import React, { useState , useEffect , useRef, useCallback} from 'react';
import { Popover, OverlayTrigger, Button, Form , Row } from 'react-bootstrap';
import { CommonService } from '../../../../services/common-services';
import './NoteCard.css'
import { BsPencilSquare } from "react-icons/bs";
import {marked} from 'marked';

const NoteCard = ({ _id, url, content, tags, userTags, onEditClick,isEditing,onCancelClick}) => {
  const renderContent = useCallback((content) => {
  const htmlContent = marked(content);
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}, []);


  const [editedContent, setEditedContent] = useState(content);
  const [showUrl, setUrl] = useState(url);
  const [showContent, setShowContent] = useState(renderContent(content));
  const [noteTags, setNoteTags] = useState(tags);
  const [tagInput,setTagInput] = useState('')
  const [isCardVisible, setIsCardVisible] = useState(true); 
  const [popoverVisible, setPopoverVisible] = useState(false);
  const cardRef = useRef(null);
  const cancelRef = useRef(null);

  const handleCancel = () => {
    setEditedContent(content)
    onCancelClick()
  }

  const handleSave = async () => {
      const res = await CommonService.editNote(showUrl,editedContent)
      setEditedContent(res.note.content)
      setShowContent(renderContent(res.note.content))
      onCancelClick()
    };

   useEffect(() => {
    async function handleClickOutside(event) {
      if (cardRef.current && !cardRef.current.contains(event.target) && !cancelRef.current.contains(event.target) ) {
        if (isEditing) {
            const res = await CommonService.editNote(showUrl,editedContent)
            setEditedContent(res.note.content)
            setShowContent(renderContent(res.note.content))
            onCancelClick()
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing,editedContent,renderContent,onCancelClick,showUrl]);

  const handleEditClick = () => {
    onEditClick()   
  };

  useEffect(()=> {
    if (isEditing) {
      if (cardRef.current) {
            cardRef.current.style.height = 'auto';
            cardRef.current.style.height = `${cardRef.current.scrollHeight}px`;
        }
    }
  },[isEditing])

  const handleContentChange = (e) => {
    setEditedContent(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

const handleSubmit = (e) => {
  e.preventDefault();
  handleSave(e);
}

  const removeNote = async () => {
      try {
      await CommonService.removeNote(_id);
      setIsCardVisible(false); 
    } catch (error) {
      console.error("Error removing note:", error);
    }
  }

  const handleTagInputChange = async (e) => {
    const newTag = e.target.value
    setTagInput(e.target.value)
    if (newTag.trim() !== '') {
      const res = await CommonService.addNoteTag(_id,newTag);
      setNoteTags(res.tags)
      setPopoverVisible(false)
    }
    setTimeout(()=> setTagInput(''),500)
  };

  const handleDeleteTag = async (tag) => {
    const res = await CommonService.removeNoteTag(_id,tag)
    setNoteTags(res.tags)
  }


   const popover = (
   <Popover id="popover-basic">
      <Popover.Body>
       <Form>
          <Form.Group controlId="formNewTag">
            <Form.Label className='p-3'>Add Tag</Form.Label>
            <Form.Select
              value ={tagInput} 
              onChange={handleTagInputChange}
              placeholder="Select a tag"
            >
              <option value="" disabled>Select a tag</option>
              {Array.from(userTags).length > 0 &&
                Array.from(userTags).map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))
              }
            </Form.Select>
          </Form.Group>
        </Form>
        <Button variant='link' onClick={removeNote}>Remove Note</Button>
      </Popover.Body>
    </Popover>
  );

   if (!isCardVisible) return null;

  return (
    <div className='note-card'>
    <div className='d-flex gap-1 align-items-center'>
       <a href={showUrl} target="_blank" rel="noopener noreferrer"><div className='url'>{showUrl}</div></a>
       <div className="flex-grow-1"></div>
      {Array.from(noteTags).length > 0 &&
        Array.from(noteTags).map((item, index) => (
          <div key={index} className='note-tag'>
            <div>{item}</div>
            <div className='remove-btn' onClick={()=> handleDeleteTag(item)}>x</div>
          </div>
        ))}
         <OverlayTrigger
          trigger="click"
          placement="bottom"
          show={popoverVisible}
          onToggle={() => setPopoverVisible(!popoverVisible)}
          overlay={popover}
          rootClose
        >
          <Button variant='link-outline'><BsPencilSquare/> Edit</Button>
        </OverlayTrigger>
    </div>
      {isEditing ? (
        <>  
         <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formNewTag" className="mb-3">
              <Form.Control 
                ref={cardRef}
                as="textarea"
                value={editedContent}
                onChange={handleContentChange}
                placeholder="Enter content"
              />
            </Form.Group>
            <Row className="mt-3">
                <Button variant="secondary" ref={cancelRef} onClick={handleCancel}>Cancel</Button>
            </Row>
          </Form>
        </>
      ) : (
          <div onClick={handleEditClick}>{showContent}</div>
      )}
      
    </div>
  );
};

export default NoteCard;
