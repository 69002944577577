import React , {useEffect} from 'react'
import { Navigate, useNavigate } from "react-router-dom";
import studentImg from '../../img/student.svg';
import startupImg from '../../img/startup.svg';
import { useAuth } from './AuthContext';


const Landing = () => {
  const {isLoggedIn,userType} = useAuth()
  let navigate = useNavigate();

  useEffect(()=> {
  },[isLoggedIn,userType])

  const goToUserSignup = () => {
    navigate("/student/signup")
  }

  const goToCompanySignup = () => {
    navigate("/company/signup")
  }

  const goToUserLogin = () => {
    navigate("/student/login")
  }

  const goToCompanyLogin = () => {
    navigate("/company/login")
  }
  // const goToStartupLogin = () => {
  //   navigate("/startup-login")
  // }

const img_style =  {
  'height' : '200px',
}

const hero_page = {
  "display" : "flex",
  "minHeight" : "80vh",
  "justifyContent" : "center",
  "alignItems" : "center",
}

const hero_item = {
  "display" : "flex",
  "flex" : "1",
  "flexDirection" : "column",
  "justifyContent" : "center",
  "alignItems" : "center",
  "gap" : "1rem"
}

const button_group = {
  "display" : "flex",
  "gap" : "1rem"
}

  return (
    <>
    {(isLoggedIn && userType === 'student')?
    <Navigate to={`/student/blogs`}/> :
      <div style={hero_page}>       
        <div style={hero_item}>
          <div style={img_style}><img src={studentImg} alt="student" height="100%" /></div>       
          <h2>Viable Lab User</h2>
          <div style={button_group}>
            <div className='button button-style1' onClick={goToUserLogin}>Login</div>
            <div className='button button-style2' onClick={goToUserSignup}>Sign Up</div>
          </div> 
        </div>
        {/* <div style={hero_item}>
          <div style={img_style}><img src={startupImg} alt="startup" height="100%" /></div> 
          <h2>Company</h2>
          <div style={button_group}>
            <div className='button button-style1' onClick={goToCompanyLogin}>Login</div>
            <div className='button button-style2' onClick={goToCompanySignup}>Sign Up</div>
          </div>      
        </div>      */}
      </div>
    }
    </>

  )
}

export default Landing
