import React, { useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";

const Courses = ({ isLoggedIn }) => {
 return (
    <>
    <div> Courses</div>
    </>
 )
}

export default  Courses;