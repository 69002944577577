import React, { useState, useEffect} from 'react';
import FilterOptions from '../FilterOptions';
import JobCard from '../JobCard/JobCard';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../common/AuthContext';
import './JobDashboard.css'
import {StudentService} from '../../../../services/student-services'
import { JobService } from '../../../../services/job-services';


const StudentJobDashboard = () => {
  const job_data_sample = [ 
	{
		posting_id: '123',
		position : 'AI Specialist',
		description : 'Seeking AI/ML expert to revolutionize data-driven insights and develop cutting-edge algorithms for enhanced decision-making.',
		employer : "ABC Company",
    employer_id : '1234',
    skill_required : ['Python', 'AI/ML'],
		open_at: '',
		close_at: 'Dec 23, 2024',
		level: 'Junior', 
		contact_email : 'sample1@abc.com',
		contact_name: 'Jane',
		company_id: '456',
		monthly_salary_range_start: '3000',
		monthly_salary_range_end: '5000',
    currency: "CAD",
		type: 'Remote',
		location: {
			city: 'Fredericton',
      province : 'New Brunswick',
			country : 'Canada',
			office_address: '123 Main Street',
		},
		status: 'Open',
	},
  {
		posting_id: '124',
		position : 'Software Developer',
		description : 'Seeking AI/ML expert to revolutionize data-driven insights and develop cutting-edge algorithms for enhanced decision-making.',
		employer : "ABC Company",
    employer_id : '1234',
    skill_required : ['Java', 'Javascript'],
		open_at: '',
		close_at: 'Jan 10, 2024',
		level: 'Junior', 
		contact_email : 'sample1@abc.com',
		contact_name: 'Jane',
		company_id: '456',
		monthly_salary_range_start: '3000',
		monthly_salary_range_end: '5000',
    currency: "CAD",
		type: 'Remote',
		location: {
			city: 'Fredericton',
      province : 'New Brunswick',
			country : 'Canada',
			office_address: '123 Main Street',
		},
		status: 'Open',
	}
]
  const {isLoggedIn,userType} = useAuth(); 
  const [keyword,setKeyword] = useState();
  const [location,setLocation] = useState();
  const [jobData, setJobData] = useState(job_data_sample);

  useEffect(() => {
    getAllJobPostings()
  },[]) 

  
 const getAllJobPostings = async () => {
    const response = await JobService.getAllJobPostings();
    console.log(response)
  }

  const handleSearch = async () => {
    const jobData = await StudentService.searchJob(keyword,location)
 
  };

  const handleClearSearch = () => {
    setLocation('')
    setKeyword('')
  };

  return (
    <div className="jobdashboard-page page">
      <div className="jobdashboard-page-content">
      <div className='search-section box-style1-fit'>
        <div className='font-size-large-title'>Search</div>
        <div className='input-item '>
          <div>Keyword</div>
          <div>
            <input 
              type='text' 
              placeholder='Position/Employer/Description'
              value={keyword}
              onChange={(e)=>setKeyword(e.target.value)}/>
          </div>
        </div>
        <div className='input-item'>
          <div>Location</div>
          <div>
            <input type='text'
              placeholder='City/Province/Country'
              value={location}
              onChange={(e)=>setLocation(e.target.value)}/>
          </div>
        </div>
        <div className='flex-row gap-half'>
          <div className='button button-style1' onClick={handleSearch}>Search</div>
          <div className='button button-style3' onClick={handleClearSearch}>Clear</div>
        </div>
      </div>
     
      <div className='joblist-section'>
         {jobData.map((posting) => (
          <JobCard
          key={posting.posting_id}
          posting = {posting}
          skills = {posting.skill_required || []}
          />
          ))}
      </div>       
      </div>
    </div>
  );
};

export default StudentJobDashboard;
