import React, { useState, useRef, useEffect } from 'react';
import jwt_decode from 'jwt-decode'
import { Link, redirect, useNavigate } from 'react-router-dom';

import '../../css/usersignup.css'
import { CommonService } from '../../services/common-services';

const google = window.google;

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [heading, setHeading] = useState('Confirm your email');
    const [password, setPassword] = useState('');
    
    const [step, setStep] = useState(1);
    const [verificationCode, setVerificationCode] = useState('');
  

    const [user, setUser] = useState({});

    const form = useRef();
    let navigate = useNavigate();

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleVerificationCodeChange = (e) => {
        setVerificationCode(e.target.value);
    };

    function ValidateEmail(str){
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(str)){
                return (true)
        }
        alert("You have entered an invalid email address!")
        return (false)
    }
    
    const handleEmailSubmit = async (e)=>{
        e.preventDefault();

      if(ValidateEmail(email)){

        const response = await CommonService.sendOTP({email: email});

        if (response?.data?.status) {

            setStep(2);
            setHeading('Set new password')
        }
        else if (response?.data?.message) {
          alert(response.data.message)
        }
        else {
          alert('Response error');
        }
      }
    }
    
    const handleVerifyCode = async (e) => {
      e.preventDefault();
      
      const response = await CommonService.changePassword({email:email, code: verificationCode, password: password})

      if (response?.data.passwordChanged) {

        alert('Password updated successfully');
        navigate(`/`);

      } else if (response?.data.tokenError) {

        alert('OTP is incorrect');

      }

    };


    return (
        <>
        <div className='student-login-page user-form'>
            {step === 1 && (
            <div className='box-style1-fit'>
                <form>
                <div className='student-login-form-content flex-column gap-1 justify-center align-center'>
                    <div className='font-size-large-title text-color'>{heading}</div>  
                    <div>
                        <input type="email" id="email" name='email' onChange={handleEmailChange} placeholder='Enter Your Email' required />
                    </div>
                        <div className='button button-style1' onClick={handleEmailSubmit}>Confirm</div> 
                <div id='signUpDiv'></div>
                </div>

            </form>
            </div>)}

            {step === 2 && (
            <div className='box-style1-fit'>
                <form>
                    <div className='student-login-form-content flex-column gap-1 justify-center align-center'>
                        <div className='font-size-large-title text-color'>{heading}</div>  
                    <div>Verification Code has been sent to your email</div>
                    <div>
                        <input type="text" id="verificationCode" name='verificationCode' onChange={handleVerificationCodeChange} placeholder='Enter Verification Code' />
                        
                    </div>
                    <div>Enter your new Password</div>
                    <div>
                        <input type="password" id="password" name='password' onChange={handlePasswordChange} placeholder='Password (8 character minimum):' required minLength={8} />
                    </div>
                        <div className='button button-style1' onClick={handleVerifyCode}>Set Password</div> 
                    </div>
                </form>
            </div>
            )}

        </div>
        </>
    );
};

export default ForgotPassword;
