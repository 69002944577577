import React, { useState, useRef, useEffect } from 'react';
import emailjs from 'emailjs-com'
import jwt_decode from 'jwt-decode'
import { Link, useNavigate } from 'react-router-dom';
import '../../../css/user.css'
import { CommonService } from '../../../services/common-services';
import { useAuth } from '../../common/AuthContext';

// const google = window.google

const StudentSignup = () => {
    const [email, setEmail] = useState('');
    const [emailValidationError,setEmailValidationError] =  useState(null)
    const [emailExistError,setEmailExistError] =  useState(null)
    const [verificationError,setVerificationError] =  useState(null)
    const [password, setPassword] = useState('');
    const [step, setStep] = useState(1);
    const [verificationCode, setVerificationCode] = useState('');
    const [code, setCode] = useState(Math.floor(100000 + Math.random() * 900000));

    // timer for resending email OTP
    const [timer, setTimer] = useState(30); // Initialize the timer to 30 seconds
    const [isLinkActive, setIsLinkActive] = useState(false); // Track whether the link is active

    let navigate = useNavigate();

    const {isLoggedIn,userType} = useAuth();
    useEffect( () => {
        if (isLoggedIn) {
        navigate(`/${userType}/homepage`)
        }
    },[isLoggedIn,userType,navigate])

    // hook for resend timer
    useEffect(() => {
      if (timer > 0) {
        const countdown = setInterval(() => {
          setTimer(prevTimer => prevTimer - 1);
        }, 1000);
  
        // Clear the interval when the component unmounts or the timer hits 0
        return () => clearInterval(countdown);
      } else {
        setIsLinkActive(true); // Activate the link when the timer reaches 0
      }
    }, [timer]);

    // function handleSignUpResponse(response){
    //     const userObj = jwt_decode(response.credential)
    //     if(userObj){
    //         navigate('/student/create-profile', { state: { email: userObj.email, password: userObj.email+userObj.email } })
    //     }else{
    //         alert('Error')
    //     }
    // }

    // useEffect(()=>{
    //     google?.accounts.id.initialize({
    //         client_id: '1041474059417-hfquknf7h9rlucg9t1knperkd9j50spr.apps.googleusercontent.com',
    //         callback: handleSignUpResponse
    //       });

    //       google?.accounts.id.renderButton(
    //         document.getElementById('signUpDiv'),
    //         {theme : 'outline', size : 'large'}
    //       );
    //     //   google.accounts.id.prompt();
    // }, [])



    function ValidateEmail(str){
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(str)){
            setEmailValidationError(null)
        return (true)
        } else {
            setEmailValidationError('Please enter a valid email address.')
        return (false)}
    }
    

    const handleRegister = async (e)=>{
        if(ValidateEmail(email)){
            const response = await CommonService.checkEmail({email})
            if (response.data.status === 400) {
                // const form = document.createElement('form');
                // // Set the form attributes
                // form.action = 'dummy-action';
                // form.method = 'POST';
                // form.id = 'temporaryForm';

                // // Prepare the template parameters
                // const templateParams = {
                //     code: code,
                //     email: email
                // }

                // // Populate the form with template parameters
                // Object.keys(templateParams).forEach(function(key) {
                //     const input = document.createElement('input');
                //     input.type = 'hidden';
                //     input.name = key;
                //     input.value = templateParams[key];
                //     form.appendChild(input);
                // });
                
                // emailjs
                // .sendForm(
                // "service_mkgt3a3",
                // "template_uz43k9m",
                // form,
                // "7w1K0pZ4EDfrYeb6A"
                // )
                // .then(
                // (result) => {
                    
                // },
                // (error) => {
                //     console.log(error.status, error.text);
                // }
                // )
                // .finally(() => {
                // // Remove the temporary form element from the DOM
                // const temporaryForm = document.getElementById('temporaryForm');
                // if (temporaryForm) {
                //     temporaryForm.parentNode.removeChild(temporaryForm);
                // }
                // });

                // send the OTP for the email
                CommonService.sendSignupOTP({email: email});

                // start the OTP timer
                setTimer(30);

                // send to next step
                setStep(2);

                // continue to step 2 of registration
                // if (response?.data?.status) {
                //   setStep(2);
                // }
                // else if (response?.data?.message) {
                //   alert(response.data.message)
                // }
                // else {
                //   alert('Response error');
                // }
            } else if (response.data.status === 200) {
                setEmailExistError('This account already exists.') 
            } 
        } 
    }

    const handleResendCode = async () => {
      // Logic to resend the code goes here
      const response = await CommonService.sendSignupOTP({email: email});

      // continue to step 2 of registration
      if (response?.data?.status) {
        console.log("Code resent!");
      }
      else if (response?.data?.message) {
        alert(response.data.message)
      }
      else {
        alert('Response error');
      }
      // Reset the timer and deactivate the link
      setTimer(30);
      setIsLinkActive(false);
    };

    const handleVerifyCode = async (e) => {
        e.preventDefault();
        const response = await CommonService.verifyOTP({ code: verificationCode, email: email })
        if (response?.tokenMatch === true) {
            navigate('/student/create-profile', { state: { email: email, password: password } })
        } else{
            setVerificationError('Please enter correct verification code')
        } 
      };


    return (
    <div className='student-register-page user-form'> 
    {step === 1 && (
        <div className='box-style1-fit'>
            <form>
            <div className='flex-column gap-1 justify-center align-center'>
            <div className='font-size-large-title text-color'>User Register</div>     
                <div>
                <input
                    type="email"
                    id="email"
                    name="email"
                    onChange={(e)=>setEmail(e.target.value)}
                    placeholder="Enter Your Email"
                    required
                />
                </div>
                {emailValidationError && <div>{emailValidationError}</div>}
                {emailExistError && <div>{emailExistError}</div>}       
                <div className='button button-style1 primary' onClick={handleRegister}>Register</div>
                {/* <div>OR</div> */}
                <div id='signUpDiv'></div>
                <div>Already a Member? <span><Link to='/student/login' >Log In</Link></span></div>
            </div>
            </form>
        </div>
        )
    } 
    {step === 2 && (
        <div className='box-style1-fit'>
            <form>
                <div className='flex-column gap-1 justify-center align-center'>
                <div className='font-size-large-title text-color'>Email Verification</div>
                <div>A verification Code has been sent to your email</div>
                <div>
                  {isLinkActive ? (
                    <a href="#" onClick={handleResendCode}>
                      Resend OTP
                    </a>
                  ) : (
                    <span className="resend-link-disabled">
                      Resend OTP in {timer} seconds
                    </span>
                  )}
                </div>
                <div>
                    <input type="text" id="verificationCode" name='verificationCode' value={verificationCode} onChange={(e)=>setVerificationCode(e.target.value)} placeholder='Enter Verification Code' />
                    {verificationError && <div>{verificationError}</div>}
                </div>
                <div>
                <input
                    type="password"
                    id="password"
                    name="password"
                    onChange={(e)=>setPassword(e.target.value)}
                    placeholder='Set Password (8 character minimum)' 
                    minLength={8} 
                    required
                />
                </div>
                    <div className='flex-row gap-1'>
                        <div className='button button-style1' onClick={handleVerifyCode}>Validate</div>
                        <div className='button button-style2' onClick={()=>setStep(1)}>Go Back</div>
                    </div>       
                </div>
            </form>
        </div>
        )}
    </div>
    );
};

export default StudentSignup;
