import React from 'react';
import './PaginationCard.css';

const PaginationCard = ({ currentPage, totalPages, setCurrentPage}) => {

  // pages data
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // render page numbers
  const renderPageNumbers = () => {
    const pagesToShow = 5;
    const pages = [];
    
    if (totalPages <= pagesToShow + 2) {
      // Show all page numbers if total pages are less than or equal to the pagesToShow plus two
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
            <button onClick={() => setCurrentPage(i)} className="page-link">
              {i}
            </button>
          </li>
        );
      }
    } else {
      // Show first few pages, ellipsis, and last few pages
      let start = Math.max(1, currentPage - 2);
      let end = Math.min(totalPages, currentPage + 2);
      
      if (currentPage <= 3) {
        end = pagesToShow;
      } else if (currentPage > totalPages - 3) {
        start = totalPages - pagesToShow + 1;
      }

      for (let i = start; i <= end; i++) {
        pages.push(
          <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
            <button onClick={() => setCurrentPage(i)} className="page-link">
              {i}
            </button>
          </li>
        );
      }

      if (start > 2) {
        pages.unshift(
          <li key="ellipsis1" className="page-item ellipsis">
            <span>...</span>
          </li>
        );
        pages.unshift(
          <li key={1} className={`page-item ${currentPage === 1 ? 'active' : ''}`}>
            <button onClick={() => setCurrentPage(1)} className="page-link">
              1
            </button>
          </li>
        );
      }

      if (end < totalPages - 1) {
        pages.push(
          <li key="ellipsis2" className="page-item ellipsis">
            <span>...</span>
          </li>
        );
        pages.push(
          <li key={totalPages} className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
            <button onClick={() => setCurrentPage(totalPages)} className="page-link">
              {totalPages}
            </button>
          </li>
        );
      }
    }

    return pages;
  };

  return (
  <nav className="pagination-nav">
      <ul className="pagination">
        {renderPageNumbers()}
      </ul>
    </nav>
  )
};

export default PaginationCard;
