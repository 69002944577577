import React, { useState, useEffect, useRef } from 'react'
import { Popover, OverlayTrigger, Button, Form } from 'react-bootstrap';
import NoteCard from './NoteCard/NoteCard';
import {CommonService} from '../../../services/common-services';

const Notes = () => {
  const [notesData, setNotesData] = useState([]);
  const [userTags,setUserTags] = useState([])
  const [newTag, setNewTag] = useState('');
  const [newNote, setNewNote] = useState({url:'',content:''});
  const [showPopover, setShowPopover] = useState(false);

  const handleTogglePopover = () => {
    setShowPopover(!showPopover);
  };

  const getAllNotes = async () => {
    const res = await CommonService.getAllNotes();
    setNotesData(res.notes);
  };

  const getAllTags = async () => {
    const res = await CommonService.getAllTags();
    setUserTags(res.userTags);
  };

  useEffect(() => {
    getAllNotes();
    getAllTags()
  }, []);


  const handleInputChange = (e) => {
    setNewTag(e.target.value);
  };

  const handleCreateTag = async (event) => {
    event.preventDefault();
    if (newTag.trim() !== '') {
      const res = await CommonService.createUserTag(newTag);
      setNewTag('');
      setUserTags(res.tags)
    }
  };

  const handleDeleteTag = async(item) => {
    const res = await CommonService.removeUserTag(item)
    setUserTags(res.tags)
  }

  const handleCreateNote =  async (e) => {
    e.preventDefault()
    setShowPopover(false)
    const res = await CommonService.createNote(newNote)
    const current_noteData = [...notesData,res.savedNote]
    setNotesData(current_noteData)
    setNewNote({url:"", content: ""})
  }

  const [editingNoteId, setEditingNoteId] = useState(null);

  const handleEditClick = (noteId) => {
    setEditingNoteId(noteId);
  };
  const handleCancelClick = () => {
    setEditingNoteId(null);
  };

  const popover = (
    <Popover id="popover-create-tag">
      <Popover.Header as="h3">Create Tag</Popover.Header>
      <Popover.Body>
        <Form onSubmit={handleCreateTag}>
          <Form.Group controlId="formNewTag">
            <Form.Control 
              type="text"
              value={newTag}
              onChange={handleInputChange}
              placeholder="Enter new tag"
            />
          </Form.Group>
        </Form>
        <hr />
       {Array.from(userTags).length > 0 &&
        Array.from(userTags).map((item, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
            <div>{item}</div>
            <Button variant="link" size="sm" onClick={() => handleDeleteTag(item)}>x</Button>
          </div>
        ))}
      </Popover.Body>
    </Popover>
  );

    const popover_create_note = (
    <Popover id="popover-create-tag">
      <Popover.Header as="h3">New Note</Popover.Header>
      <Popover.Body>
        <Form onSubmit={(e)=>handleCreateNote(e)}>
          <Form.Group controlId="formNoteUrl" className="mb-2">
          <Form.Label>URL</Form.Label>
              <Form.Control 
                type="text"
                value={newNote.url}
                onChange={(e) => setNewNote({ ...newNote, url: e.target.value })}
                placeholder="Enter note URL"
                required
              />
            </Form.Group>

          <Form.Group controlId="formNoteContent" className="mb-2">
            <Form.Label>Content</Form.Label>
            <Form.Control 
              as="textarea"
              rows={4}
              value={newNote.content}
              onChange={(e) => setNewNote({ ...newNote, content: e.target.value })}
              placeholder="Enter note content"
            />
          </Form.Group>
          <Button type='submit'  variant="primary" className="mt-2">Create</Button>
        </Form>       
      </Popover.Body>
    </Popover>
  );
   
 return (
    <>
    <div className='d-flex gap-1 mb-4'>
    <h2>My Notes</h2>
    <div className="flex-grow-1"></div>
     <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose >
        <Button variant='link-outline'>+ Tag</Button>
      </OverlayTrigger>

      <OverlayTrigger trigger="click" placement="bottom" overlay={popover_create_note} rootClose  show={showPopover} onToggle={handleTogglePopover}>
        <Button variant='primary'>+ New Note</Button>
      </OverlayTrigger>
    </div>
    
    {(notesData.length !== 0) && (<div className="note-list-container">
      <div className="note-list d-flex flex-column-reverse">
        {notesData.map((note, index) => (
          <NoteCard 
          key={index} 
          userTags={userTags} 
          isEditing={editingNoteId === index} 
          onEditClick={() => handleEditClick(index)}
          onCancelClick={handleCancelClick}
         {...note} />
        ))}
      </div>
    </div>
    )}
    {(notesData.length === 0) && (
      <div className='text-gray'>Use the power of viable-lab extension to create note from any website...</div>
    )}
    </>
 )
}

export default  Notes;