import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const getUserType = () => localStorage.getItem('user_type');
  const getUserId = () => localStorage.getItem('user_id');
  const getToken = () => localStorage.getItem('token');
  const [userType, setUserType] = useState(getUserType());
  const [userId, setUserId] = useState(getUserId());
  const [isLoggedIn, setLoggedIn] = useState(!!getToken());

  const login = (token, userType, userId) => {
    localStorage.setItem('user_type', userType);
    localStorage.setItem('token', token);
    localStorage.setItem('user_id', userId);
    setLoggedIn(true);
    setUserType(userType);
    setUserId(userId);
  };

  const logout = () => {
    setLoggedIn(false);
    setUserType(null);
    setUserId(null);
    localStorage.removeItem('token');
    localStorage.removeItem('user_type');
    localStorage.removeItem('user_id');
    navigate('/');
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, setLoggedIn, userType, login, logout, userId }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
