import React from 'react';
import './BlogCard.css';
import { CommonService } from '../../../../services/common-services';

const BlogCard = ({ url, title, overview, updatedate, l1 }) => {

  const getPostgresFormattedDate = () => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleURLClick = (event) => {
    event.preventDefault();

    // call backend api
    CommonService.openedBlog(url, getPostgresFormattedDate());

    // open the blog
    window.open(url, '_blank');
  };

  // target="_blank" rel="noopener noreferrer"
  return (
  <div className="blog-card box-style1">
    <h6><a href={url} onClick={handleURLClick}>{title}</a></h6>

    <p>{overview.slice(0, 100) + '...'}</p>
    {l1 && <div className="blog-tag-l1">{l1}</div>}
    {updatedate && <div className="blog-tag-date">{updatedate}</div>}
  </div>
  )
};

export default BlogCard;
