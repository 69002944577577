import React, { useState } from 'react'
import data from '../../../data/sampledata'
import Modal from 'react-modal';
import '../../../css/userhomepage.css'
import { useAuth } from '../../common/AuthContext';


const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width:'400px',
      height:'auto',
      overflow: 'scroll',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  

const StudentHomepage = () => {
    const currentUser_id = 2;
    const { userType } = useAuth();

    const getUsername = (userId) => {
        const user = data.users.find(user => user.id === userId);
        const fullname = [user.firstname,user.lastname]
        return fullname.join(' ');
      };
      
    const submitSolution= () => {
        console.log('submit')
    }

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
    setIsOpen(true);
    }

    function afterOpenModal() {
    // references are now sync'd and can be accessed.

    }

    function closeModal() {
    setIsOpen(false);
    }

    function submit() {
    console.log('Submit')
    }

    function search() {
        console.log('Search')
        }

    return (
        <>
        {(userType === 'student')&& 
        <div className='userhomepage-page'> 
            <div className='userhomepage-content'>
                <div className='userhomepage-left'>
                    {/* Project */}
                    <div className='project-container box-style1'>
                        <div className='project-content flex-column-style1'>
                            <div className='title-style1'>Brainstorming Board</div>
                            <div className='project-search'>
                                <div>Search</div>
                                <div className='userhomepage-search-input-group'>
                                    <div className='userhomepage-search-input'><input type='text'></input></div>
                                    <div className='userhomepage-search-button' onClick={search}>Search</div>
                                </div>
                                
                            </div>
                            <div className='project-filter'>
                                <div>Filter</div>
                                <div>Category</div>
                                <div>Location</div>
                            </div>
                            <div className='project-item-group'>
                                {data.brainstorm_board.map((item,index) => 
                                    // Problem
                                    <div className='project-item' key={index}>
                                        <div>
                                            <div className='title-style2'>{item.title}</div>
                                            <div className='font-size-xsmall text-grey'>{'Posted on '+ item.date}</div>
                                        </div>
                                    <div>{item.description.split(' ').slice(0, 100).join(' ')+"..."}</div>

                                    {/* My Solution */}
                                    <div className='create-new-button' onClick={openModal}>Create New Solution</div>                        
                            
                                    {/* Posted Solution */}
                                    <div className='answer-items'>
                                        {item.answers.map((item2,index2)=>
                                        <div className='answer-item font-size-small ' key={index2}>
                                            <div className='bold font-size-medium'>{item2.title}</div>
                                            <div>{item2.content.split(' ').slice(0, 20).join(' ')+"..."}</div>
                                            <div className='answer-user-info'>
                                                <div>{'By '+ getUsername(item2.user_id)}</div>
                                                <div>{item2.likes+' like(s)'}</div>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>  
                </div>
                                            
                <div className='userhomepage-right'>
                    {/* Blogs */}
                    <div className='blogs-container box-style1 '>
                        <div className='blog-content flex-column-style1'>
                            <div className='title-style1'>Explore Trending Blogs</div>
                            <div className='blogs-items   box-style2'>
                                {data.blogs.map((item) => (
                                    <div key={item.id}>
                                        <div><a href={item.url}>{item.title}</a></div>
                                        <div className='blog-info'>
                                            <div className='blog-length font-size-xsmall text-grey'>{item.length+' min read'}</div>
                                            <div className='font-size-xsmall text-blue'>{item.likes+' like(s)'}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>                   
                        </div>

                    </div>

                    {/* Recommended Courses */}
                    <div className='courses-container box-style1 '>
                        <div className='courses-content flex-column-style1'>
                        <div className='title-style1'>Recommended Courses</div>
                            <div className='courses-items box-style2'>
                                <div className='courses-item-flex' >
                                    <div className='coursename bold'>Course Name</div>
                                    <div className=' bold'>Institute</div>
                                </div>
                                {data.courses.map((item) => (
                                    <div className='courses-item-flex' key={item.id}>
                                        <div  className='coursename'> {item.coursename}</div>
                                        <div>{item.school}</div>
                                    </div>
                                ))}
                            </div>    
                        </div>
                    </div>

                    {/* Top profiles */}
                    <div className='profiles-container box-style1'>
                        <div className='courses-content flex-column-style1'>
                        <div className='title-style1'>Top Profiles</div>
                            <div className='profiles-items box-style2'>
                                <div className='profile-item-flex'>
                                    <div className='profile-name bold'>Name</div>
                                    <div className=' bold'>Score</div>
                                </div>
                                
                                {data.users.map((item) => (
                                    <div className='profile-item-flex' key={item.id}>
                                        <div className='profile-name'>{item.firstname+' '+item.lastname}</div>
                                        <div>{item.score}</div>
                                    </div>
                                ))}
                            </div>    
                        </div>
                    </div>
                </div>

            </div>   
        </div>
        }
            
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="New Idea Modal"
            >
                <div className='new-solution-modal-content'>
                    <div className='title-style2 font-size-small-title'>Create New Solution</div>
                    <div>
                        <div>Title</div>
                        <div><input type='text' placeholder='New title'></input></div>
                    </div>
                    <div>
                        <div>Content</div>
                        <div><textarea type='text' placeholder='Enter your solution here'/></div>
                    </div>
                    <div><a href='#'>Attach file(s)</a></div>
                    <div className='modal-button-group'>
                        <div className='button-style2 fit-content' onClick={submit}>Save</div>
                        <div className='button-style1 fit-content' onClick={closeModal}>Close</div>
                    </div>
                </div>
               
            </Modal>
            
        </>
    )
}

export default StudentHomepage;