import axios from "axios";

// get backend endpoint
const backend_endpoint = process.env.REACT_APP_BACKEND_ENDPOINT;

const getAllJobPostings = async () => {
    try {
      const response = await axios.get(`${backend_endpoint}/job/getAllJobPostings`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response
    } catch (error) {
      console.error('Error:', error.message);    
    }
}


export const JobService = {
  getAllJobPostings
};