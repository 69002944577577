import React, { useState } from 'react';

function StudentFileUpload() {
  const [file, setFile] = useState();

  // get backend endpoint
  const backend_endpoint = process.env.REACT_APP_BACKEND_ENDPOINT;

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(backend_endpoint + '/resume/upload', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        const data = await response.json();
        alert(`File uploaded. Link: ${data.link}`);
      }
    } catch (error) {
      console.error('Error uploading file: ', error);
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <input type="file" onChange={handleFileChange} />
      <button type="submit">Upload</button>
    </form>
  );
}

export default StudentFileUpload;
