const data = {
    brainstorm_board: [
        {
            id: 1,
            title: 'Satisfying Daily needs on Mars',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend tincidunt pellentesque. Sed a nulla semper, congue lacus et, fringilla lectus. Integer molestie, justo non rhoncus pellentesque, justo purus imperdiet tellus, eu semper mauris massa sit amet nibh. Nullam pulvinar turpis vitae consequat luctus. Proin nec sapien ac lorem tempor scelerisque ac vel enim. Vestibulum non bibendum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris enim erat, convallis vel rhoncus quis, mattis eu ex. Nullam aliquam orci quis dolor tempor, vel dignissim mi tempus. Sed tristique tincidunt vulputate. Pellentesque ut tincidunt nibh, in ultrices ligula. Ut pulvinar tellus non lacus blandit, sollicitudin tristique libero condimentum. Morbi ac ultrices diam, ut aliquam quam. Nunc enim nibh, consectetur vitae condimentum in, vestibulum nec quam. Aenean sed est odio. ',
            date: 'Jan 28, 2023' ,
            video: '',
            answers: [
                {
                    id: 1,
                    title: 'First solution',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend tincidunt pellentesque. Sed a nulla semper, congue lacus et, fringilla lectus. Integer molestie, justo non rhoncus pellentesque, justo purus imperdiet tellus, eu semper mauris massa sit amet nibh. Nullam pulvinar turpis vitae consequat luctus. Proin nec sapien ac lorem tempor scelerisque ac vel enim. Vestibulum non bibendum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris enim erat, convallis vel rhoncus quis, mattis eu ex. Nullam aliquam orci quis dolor tempor, vel dignissim mi tempus. Sed tristique tincidunt vulputate. Pellentesque ut tincidunt nibh, in ultrices ligula. Ut pulvinar tellus non lacus blandit, sollicitudin tristique libero condimentum. Morbi ac ultrices diam, ut aliquam quam. Nunc enim nibh, consectetur vitae condimentum in, vestibulum nec quam. Aenean sed est odio. ',
                    username: 'username1',
                    user_id: 1,
                    likes: 50,

                },
                {
                    id: 2,
                    title: 'Second solution',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend tincidunt pellentesque. Sed a nulla semper, congue lacus et, fringilla lectus. Integer molestie, justo non rhoncus pellentesque, justo purus imperdiet tellus, eu semper mauris massa sit amet nibh. Nullam pulvinar turpis vitae consequat luctus. Proin nec sapien ac lorem tempor scelerisque ac vel enim. Vestibulum non bibendum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris enim erat, convallis vel rhoncus quis, mattis eu ex. Nullam aliquam orci quis dolor tempor, vel dignissim mi tempus. Sed tristique tincidunt vulputate. Pellentesque ut tincidunt nibh, in ultrices ligula. Ut pulvinar tellus non lacus blandit, sollicitudin tristique libero condimentum. Morbi ac ultrices diam, ut aliquam quam. Nunc enim nibh, consectetur vitae condimentum in, vestibulum nec quam. Aenean sed est odio. ',
                    username: 'username1',
                    user_id: 3,
                    likes: 50,

                },
                {
                    id: 3,
                    title: 'Third solution',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend tincidunt pellentesque. Sed a nulla semper, congue lacus et, fringilla lectus. Integer molestie, justo non rhoncus pellentesque, justo purus imperdiet tellus, eu semper mauris massa sit amet nibh. Nullam pulvinar turpis vitae consequat luctus. Proin nec sapien ac lorem tempor scelerisque ac vel enim. Vestibulum non bibendum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris enim erat, convallis vel rhoncus quis, mattis eu ex. Nullam aliquam orci quis dolor tempor, vel dignissim mi tempus. Sed tristique tincidunt vulputate. Pellentesque ut tincidunt nibh, in ultrices ligula. Ut pulvinar tellus non lacus blandit, sollicitudin tristique libero condimentum. Morbi ac ultrices diam, ut aliquam quam. Nunc enim nibh, consectetur vitae condimentum in, vestibulum nec quam. Aenean sed est odio. ',
                    username: 'username1',
                    user_id: 2,
                    likes: 50,

                },
                {
                    id: 4,
                    title: 'Fourth solution',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend tincidunt pellentesque. Sed a nulla semper, congue lacus et, fringilla lectus. Integer molestie, justo non rhoncus pellentesque, justo purus imperdiet tellus, eu semper mauris massa sit amet nibh. Nullam pulvinar turpis vitae consequat luctus. Proin nec sapien ac lorem tempor scelerisque ac vel enim. Vestibulum non bibendum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris enim erat, convallis vel rhoncus quis, mattis eu ex. Nullam aliquam orci quis dolor tempor, vel dignissim mi tempus. Sed tristique tincidunt vulputate. Pellentesque ut tincidunt nibh, in ultrices ligula. Ut pulvinar tellus non lacus blandit, sollicitudin tristique libero condimentum. Morbi ac ultrices diam, ut aliquam quam. Nunc enim nibh, consectetur vitae condimentum in, vestibulum nec quam. Aenean sed est odio. ',
                    username: 'username1',
                    likes: 50,
                    user_id: 1,
                },

            ]
        },
        {
            id: 2,
            title: 'Another problem',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend tincidunt pellentesque. Sed a nulla semper, congue lacus et, fringilla lectus. Integer molestie, justo non rhoncus pellentesque, justo purus imperdiet tellus, eu semper mauris massa sit amet nibh. Nullam pulvinar turpis vitae consequat luctus. Proin nec sapien ac lorem tempor scelerisque ac vel enim. Vestibulum non bibendum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris enim erat, convallis vel rhoncus quis, mattis eu ex. Nullam aliquam orci quis dolor tempor, vel dignissim mi tempus. Sed tristique tincidunt vulputate. Pellentesque ut tincidunt nibh, in ultrices ligula. Ut pulvinar tellus non lacus blandit, sollicitudin tristique libero condimentum. Morbi ac ultrices diam, ut aliquam quam. Nunc enim nibh, consectetur vitae condimentum in, vestibulum nec quam. Aenean sed est odio. ',
            video: '',
            date: 'Jan 28, 2023' ,
            answers: [
                {
                    id: 1,
                    title: 'First solution',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend tincidunt pellentesque. Sed a nulla semper, congue lacus et, fringilla lectus. Integer molestie, justo non rhoncus pellentesque, justo purus imperdiet tellus, eu semper mauris massa sit amet nibh. Nullam pulvinar turpis vitae consequat luctus. Proin nec sapien ac lorem tempor scelerisque ac vel enim. Vestibulum non bibendum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris enim erat, convallis vel rhoncus quis, mattis eu ex. Nullam aliquam orci quis dolor tempor, vel dignissim mi tempus. Sed tristique tincidunt vulputate. Pellentesque ut tincidunt nibh, in ultrices ligula. Ut pulvinar tellus non lacus blandit, sollicitudin tristique libero condimentum. Morbi ac ultrices diam, ut aliquam quam. Nunc enim nibh, consectetur vitae condimentum in, vestibulum nec quam. Aenean sed est odio. ',
                    username: 'username1',
                    likes: 50,
                    user_id: 1,
                },
                {
                    id: 2,
                    title: 'Second solution',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend tincidunt pellentesque. Sed a nulla semper, congue lacus et, fringilla lectus. Integer molestie, justo non rhoncus pellentesque, justo purus imperdiet tellus, eu semper mauris massa sit amet nibh. Nullam pulvinar turpis vitae consequat luctus. Proin nec sapien ac lorem tempor scelerisque ac vel enim. Vestibulum non bibendum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris enim erat, convallis vel rhoncus quis, mattis eu ex. Nullam aliquam orci quis dolor tempor, vel dignissim mi tempus. Sed tristique tincidunt vulputate. Pellentesque ut tincidunt nibh, in ultrices ligula. Ut pulvinar tellus non lacus blandit, sollicitudin tristique libero condimentum. Morbi ac ultrices diam, ut aliquam quam. Nunc enim nibh, consectetur vitae condimentum in, vestibulum nec quam. Aenean sed est odio. ',
                    username: 'username1',
                    likes: 50,
                    user_id: 1,
                },
                {
                    id: 3,
                    title: 'Third solution',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend tincidunt pellentesque. Sed a nulla semper, congue lacus et, fringilla lectus. Integer molestie, justo non rhoncus pellentesque, justo purus imperdiet tellus, eu semper mauris massa sit amet nibh. Nullam pulvinar turpis vitae consequat luctus. Proin nec sapien ac lorem tempor scelerisque ac vel enim. Vestibulum non bibendum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris enim erat, convallis vel rhoncus quis, mattis eu ex. Nullam aliquam orci quis dolor tempor, vel dignissim mi tempus. Sed tristique tincidunt vulputate. Pellentesque ut tincidunt nibh, in ultrices ligula. Ut pulvinar tellus non lacus blandit, sollicitudin tristique libero condimentum. Morbi ac ultrices diam, ut aliquam quam. Nunc enim nibh, consectetur vitae condimentum in, vestibulum nec quam. Aenean sed est odio. ',
                    username: 'username1',
                    likes: 50,
                    user_id: 1,
                },
                {
                    id: 4,
                    title: 'Fourth solution',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend tincidunt pellentesque. Sed a nulla semper, congue lacus et, fringilla lectus. Integer molestie, justo non rhoncus pellentesque, justo purus imperdiet tellus, eu semper mauris massa sit amet nibh. Nullam pulvinar turpis vitae consequat luctus. Proin nec sapien ac lorem tempor scelerisque ac vel enim. Vestibulum non bibendum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris enim erat, convallis vel rhoncus quis, mattis eu ex. Nullam aliquam orci quis dolor tempor, vel dignissim mi tempus. Sed tristique tincidunt vulputate. Pellentesque ut tincidunt nibh, in ultrices ligula. Ut pulvinar tellus non lacus blandit, sollicitudin tristique libero condimentum. Morbi ac ultrices diam, ut aliquam quam. Nunc enim nibh, consectetur vitae condimentum in, vestibulum nec quam. Aenean sed est odio. ',
                    username: 'username1',
                    likes: 50,
                    user_id: 1,
                },

            ]
        },
        {
            id: 3,
            title: 'The big problem',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend tincidunt pellentesque. Sed a nulla semper, congue lacus et, fringilla lectus. Integer molestie, justo non rhoncus pellentesque, justo purus imperdiet tellus, eu semper mauris massa sit amet nibh. Nullam pulvinar turpis vitae consequat luctus. Proin nec sapien ac lorem tempor scelerisque ac vel enim. Vestibulum non bibendum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris enim erat, convallis vel rhoncus quis, mattis eu ex. Nullam aliquam orci quis dolor tempor, vel dignissim mi tempus. Sed tristique tincidunt vulputate. Pellentesque ut tincidunt nibh, in ultrices ligula. Ut pulvinar tellus non lacus blandit, sollicitudin tristique libero condimentum. Morbi ac ultrices diam, ut aliquam quam. Nunc enim nibh, consectetur vitae condimentum in, vestibulum nec quam. Aenean sed est odio. ',
            video: '',
            date: 'Jan 28, 2023' ,
            answers: [
                {
                    id: 1,
                    title: 'First solution',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend tincidunt pellentesque. Sed a nulla semper, congue lacus et, fringilla lectus. Integer molestie, justo non rhoncus pellentesque, justo purus imperdiet tellus, eu semper mauris massa sit amet nibh. Nullam pulvinar turpis vitae consequat luctus. Proin nec sapien ac lorem tempor scelerisque ac vel enim. Vestibulum non bibendum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris enim erat, convallis vel rhoncus quis, mattis eu ex. Nullam aliquam orci quis dolor tempor, vel dignissim mi tempus. Sed tristique tincidunt vulputate. Pellentesque ut tincidunt nibh, in ultrices ligula. Ut pulvinar tellus non lacus blandit, sollicitudin tristique libero condimentum. Morbi ac ultrices diam, ut aliquam quam. Nunc enim nibh, consectetur vitae condimentum in, vestibulum nec quam. Aenean sed est odio. ',
                    username: 'username1',
                    likes: 50,
                    user_id: 1,
                },
                {
                    id: 2,
                    title: 'Second solution',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend tincidunt pellentesque. Sed a nulla semper, congue lacus et, fringilla lectus. Integer molestie, justo non rhoncus pellentesque, justo purus imperdiet tellus, eu semper mauris massa sit amet nibh. Nullam pulvinar turpis vitae consequat luctus. Proin nec sapien ac lorem tempor scelerisque ac vel enim. Vestibulum non bibendum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris enim erat, convallis vel rhoncus quis, mattis eu ex. Nullam aliquam orci quis dolor tempor, vel dignissim mi tempus. Sed tristique tincidunt vulputate. Pellentesque ut tincidunt nibh, in ultrices ligula. Ut pulvinar tellus non lacus blandit, sollicitudin tristique libero condimentum. Morbi ac ultrices diam, ut aliquam quam. Nunc enim nibh, consectetur vitae condimentum in, vestibulum nec quam. Aenean sed est odio. ',
                    username: 'username1',
                    likes: 50,
                    user_id: 1,
                },
                {
                    id: 3,
                    title: 'Third solution',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend tincidunt pellentesque. Sed a nulla semper, congue lacus et, fringilla lectus. Integer molestie, justo non rhoncus pellentesque, justo purus imperdiet tellus, eu semper mauris massa sit amet nibh. Nullam pulvinar turpis vitae consequat luctus. Proin nec sapien ac lorem tempor scelerisque ac vel enim. Vestibulum non bibendum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris enim erat, convallis vel rhoncus quis, mattis eu ex. Nullam aliquam orci quis dolor tempor, vel dignissim mi tempus. Sed tristique tincidunt vulputate. Pellentesque ut tincidunt nibh, in ultrices ligula. Ut pulvinar tellus non lacus blandit, sollicitudin tristique libero condimentum. Morbi ac ultrices diam, ut aliquam quam. Nunc enim nibh, consectetur vitae condimentum in, vestibulum nec quam. Aenean sed est odio. ',
                    username: 'username1',
                    likes: 50,
                    user_id: 1,
                },
                {
                    id: 4,
                    title: 'Fourth solution',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend tincidunt pellentesque. Sed a nulla semper, congue lacus et, fringilla lectus. Integer molestie, justo non rhoncus pellentesque, justo purus imperdiet tellus, eu semper mauris massa sit amet nibh. Nullam pulvinar turpis vitae consequat luctus. Proin nec sapien ac lorem tempor scelerisque ac vel enim. Vestibulum non bibendum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris enim erat, convallis vel rhoncus quis, mattis eu ex. Nullam aliquam orci quis dolor tempor, vel dignissim mi tempus. Sed tristique tincidunt vulputate. Pellentesque ut tincidunt nibh, in ultrices ligula. Ut pulvinar tellus non lacus blandit, sollicitudin tristique libero condimentum. Morbi ac ultrices diam, ut aliquam quam. Nunc enim nibh, consectetur vitae condimentum in, vestibulum nec quam. Aenean sed est odio. ',
                    username: 'username1',
                    likes: 50,
                    user_id: 1,
                },

            ]
        },
    ],
    blogs: [
        {
            id: 1,
            title: 'Storage challenges in the evolution of database architecture',
            length: 12,
            likes: 30,
            url: '',
        },
        {
            id: 2,
            title: 'Blog 2',
            length: 12,
            likes: 30,
            url: '',
        },
        {
            id: 3,
            title: 'Blog 3',
            length: 12,
            likes: 30,
            url: '',
        },
        {
            id: 4,
            title: 'Blog 4',
            length: 12,
            likes: 30,
            url: '',
        },
        {
            id: 5,
            title: 'Blog 5',
            length: 12,
            likes: 30,
            url: '',
        },

    ],
    courses: [
        {
            id: 1,
            school: 'UNB',
            coursename: 'Introduction to Computer Programming',
            url: '',
        },
        {
            id: 2,
            school: 'Harvard',
            coursename: 'Course 2',
            url: '',
        },
        {
            id: 3,
            school: 'Algonquin',
            coursename: 'Course 3',
            url: '',
        },
        {
            id: 4,
            school: 'NBCC',
            coursename: 'Course 4',
            url: '',
        },

    ],
    users: [
        {
            id: 1,
            firstname: 'Jane',
            lastname: 'Doe',
            score: 950,
            education: [
                'UNB', 'Harvard'
            ],
            work:
                [
                    'Meta', 'Apple'
                ],
            followers: [
                3, 4, 5, 6
            ]
        },
        {
            id: 2,
            firstname: 'Christ',
            lastname: 'Brown',
            score: 800,
            education: [
                'UNB', 'NBCC'
            ],
            work:
                [
                    'Google'
                ],
            followers: [
                1, 4, 5
            ]
        },
        {
            id: 3,
            firstname: 'Nancy',
            lastname: 'Brown',
            score: 800,
            education: [
                'UNB', 'NBCC'
            ],
            work:
                [
                    'Google'
                ],
            followers: [
                1, 4, 5
            ]
        },
        {
            id: 4,
            firstname: 'Coral',
            lastname: 'Brown',
            score: 800,
            education: [
                'UNB', 'NBCC'
            ],
            work:
                [
                    'Google'
                ],
            followers: [
                1, 4, 5
            ]
        }
    ],
}

export default data;