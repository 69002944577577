import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { StudentService} from '../../../services/student-services';


const StudentRegistrationForm = () => {
  const [credentials, setCredentials] = useState({ name: "", email:"", phone:"", university:"", degree:"", graduation:"", availability:"", previousExp:"", github:"", linkedin:"", file: null, desc:"", password:"", cpassword:"" });
  const [checkBox, setCheckBox] = useState({expertise:[], programming:[], industry:[]})
  const [error, setError] = useState()
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {name, email, phone, university, degree, graduation, availability, previousExp, github, linkedin, file, desc, password, cpassword} = credentials;
    const {expertise, programming, industry} = checkBox;

      // Create a new FormData object
      const formData = new FormData();
      formData.append('file', file);

      const otherFields = { name, email, phone, university, degree, graduation, availability, previousExp, github, linkedin, desc, password, cpassword, expertise, programming, industry };
      const otherFieldsJson = JSON.stringify(otherFields);
      formData.append('otherFields', otherFieldsJson);

      const checkIfAccountExists = await StudentService.checkIfAccountExists();
      
      if (checkIfAccountExists) {
        setError('Account already exists.')
      }
      else {
        const response = await StudentService.createStudent(formData);
        const json = await response.json();
        if (json.success) {
          navigate('/student-login');
        } else {
          setError('Failed to register.')
        } 
      }
  };

  const onChange = (e) => {
      // Otherwise, update the credentials state as usual
      setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const checkBoxOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const checked = e.target.checked;
  
    setCheckBox((prevCheckBox) => {
      return {
        ...prevCheckBox,
        [name]: checked ? [...prevCheckBox[name], value] : prevCheckBox[name].filter((item) => item !== value),
      };
    });
  };

  const onFileChange = (e) => {
    setCredentials({ ...credentials, file: e.target.files[0] });  
  };



  return (
    <div className="container" style={{"width":"850px"}}>
    <h2>Student Registration Form</h2>
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name='name' onChange={onChange} placeholder="Enter your name" required />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name='email' onChange={onChange} placeholder="Enter your email" required />
      </div>   
      <div className="form-group">
        <label htmlFor="phone">Phone Number:</label>
        <input type="tel" id="phone" name='phone' onChange={onChange} placeholder="Enter your phone no" pattern="[0-9]{10}" required />
      </div>  
      <div className="form-group">
        <label htmlFor="university">University/Institution:</label>
        <input type="text" id="university" name='university' onChange={onChange} placeholder="Enter your university name" required />
      </div>
      <div className="form-group">
        <label htmlFor="degree">Degree Program:</label>
        <input type="text" id="degree" name='degree' onChange={onChange} placeholder="Enter your degree name" required />
      </div>
      <div className="form-group">
        <label htmlFor="graduation">Expected Graduation Year:</label>
        <input type="number" id="graduation" name='graduation' onChange={onChange} placeholder="Enter your expevted graduation year" required />
      </div> 

       <div className="form-group">
        <label htmlFor="expertise">Areas of Expertise:</label>
          <div className='d-flex flex-wrap'>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="Web Development" name='expertise' />
              <label htmlFor="web" className='my-2' style={{"fontWeight":"100"}}>Web Development</label>
            </div>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="Mobile App Development" name='expertise' />
              <label htmlFor="app" className='my-2' style={{"fontWeight":"100"}}>Mobile App Development</label>
            </div>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="Data Science" name='expertise' />
              <label htmlFor="datascience" className='my-2' style={{"fontWeight":"100"}}>Data Science</label>
            </div>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="Artificial Intelligence" name='expertise' />
              <label htmlFor="ai" className='my-2' style={{"fontWeight":"100"}}>Artificial Intelligence</label>
            </div>
            <div className='d-flex mx-4'> 
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="Machine Learning" name='expertise'/>
              <label htmlFor="ml" className='my-2' style={{"fontWeight":"100"}}>Machine Learning</label>
            </div>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="Cyber Security" name='expertise' />
              <label htmlFor="cyber" className='my-2' style={{"fontWeight":"100"}}>Cyber Security</label>
            </div>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="UI/UX Design" name='expertise' />
              <label htmlFor="uiux" className='my-2' style={{"fontWeight":"100"}}>UI/UX Design</label>
            </div>
            {/* <div className='d-flex mx-4'>
              <label htmlFor="other" className='my-2 mx-2' style={{"fontWeight":"100"}}>Other:</label>
              <input type="text" id="other" />
            </div>  */}
          </div>
      </div> 

      <div className="form-group">
        <label htmlFor="programming">Programming Language:</label>
          <div className='d-flex flex-wrap'>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="Java" name='programming' />
              <label htmlFor="java" className='my-2' style={{"fontWeight":"100"}}>Java</label>
            </div>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="Python" name='programming' />
              <label htmlFor="python" className='my-2' style={{"fontWeight":"100"}}>Python</label>
            </div>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="C++" name='programming' />
              <label htmlFor="cpp" className='my-2' style={{"fontWeight":"100"}}>C++</label>
            </div>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="JavaScript" name='programming' />
              <label htmlFor="javascript" className='my-2' style={{"fontWeight":"100"}}>JavScript</label>
            </div>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="Ruby" name='programming' />
              <label htmlFor="ruby" className='my-2' style={{"fontWeight":"100"}}>Ruby</label>
            </div>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="Swift" name='programming' />
              <label htmlFor="swift" className='my-2' style={{"fontWeight":"100"}}>Swift</label>
            </div>
             {/* <div className='d-flex mx-4'>
              <label htmlFor="other" className='my-2 mx-2' style={{"fontWeight":"100"}}>Other:</label>
              <input type="text" id="other" />
            </div>  */}
          </div>
      </div>

      <div className="form-group">
        <label htmlFor="industry">Industry Interest:</label>
          <div className='d-flex flex-wrap'>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="Software Development" name='industry' />
              <label htmlFor="software" className='my-2' style={{"fontWeight":"100"}}>Software Development</label>
            </div>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="E-commerce" name='industry' />
              <label htmlFor="ecommerce" className='my-2' style={{"fontWeight":"100"}}>E-commerce</label>
            </div>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="HealtTech" name='industry' />
              <label htmlFor="healthtech" className='my-2' style={{"fontWeight":"100"}}>HealtTech</label>
            </div>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="FinTech" name='industry' />
              <label htmlFor="fintech" className='my-2' style={{"fontWeight":"100"}}>FinTech</label>
            </div>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="Gaming" name='industry' />
              <label htmlFor="gaming" className='my-2' style={{"fontWeight":"100"}}>Gaming</label>
            </div>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="Ed Tech" name='industry' />
              <label htmlFor="edtech" className='my-2' style={{"fontWeight":"100"}}>Ed Tech</label>
            </div>
            <div className='d-flex mx-4'>
              <input className='mx-2' style={{"width":"13px"}} type="checkbox" onChange={checkBoxOnChange} value="IOT" name='industry' />
              <label htmlFor="iot" className='my-2' style={{"fontWeight":"100"}}>IOT</label>
            </div>
            {/* <div className='d-flex mx-4'>
              <label htmlFor="other" className='my-2 mx-2' style={{"fontWeight":"100"}}>Other:</label>
              <input type="text" id="other" />
            </div> */}
          </div>
      </div> 

      <div className="form-group d-flex">
        <label htmlFor="availability" className='mx-2'>Availability:</label>
        <select id="availability" onChange={onChange} name="availability" style={{"width":"100px"}}>
          <option>Part-time (up to 20 hours per week)</option>
          <option>Full-time (more than 20 hours per week)</option>
          <option>Flexible</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="previousExp">Previous Internships/Experience:</label>
        <input type="text" id="previousExp" name='previousExp' onChange={onChange} placeholder="Enter your previous experience" required />
      </div>
      <div className="form-group">
        <label htmlFor="github">GitHub/Portfolio URL:</label>
        <input  type="url" id="github" name='github' onChange={onChange} placeholder="GitHub Profile" required />
      </div>
      <div className="form-group">
        <label htmlFor="linkedin">LinkedIn Profile:</label>
        <input  type="url" id="linkedin" name='linkedin' onChange={onChange} placeholder="LinkedIn url" required />
      </div>
      <div className="form-group">
        <label htmlFor="file">Upload Your Resume:</label>
        <input  type="file" onChange={onFileChange} id="file" name='file' required />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password:</label>
        <input  type="password" id="password" name='password' onChange={onChange} placeholder="Enter your password" required />
      </div>
      <div className="form-group">
        <label htmlFor="cpassword">Confirm Password:</label>
        <input  type="password" id="cpassword" name='cpassword' onChange={onChange} placeholder="Confirm password" required />
      </div>
      <div className="form-group">
        <label htmlFor="desc">Where do you want to see yourself in next five years:</label>
        <textarea  type="text" id="desc" name='desc' onChange={onChange} placeholder="Tell me about yourself" required />
      </div>

      <div className="form-group">
        <button type="submit">Register</button>
        {error && <div>{error}</div>}
      </div>
    </form>

    <div>
    
    </div>
  </div>
  )
}

export default StudentRegistrationForm;