import React, { useState, useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import { Link, Navigate, useAsyncError, useNavigate } from 'react-router-dom';
import '../../../css/user.css'
import { useAuth } from '../../common/AuthContext';
import { StudentService } from '../../../services/student-services';

// const google = window.google;

const StudentLogin = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorLogin,setErrorLogin] = useState(null)

  const {isLoggedIn, userType, login } = useAuth(); 
  useEffect( () => {
    if (isLoggedIn) {
      navigate(`/${userType}/homepage`)
    }
  },[isLoggedIn,userType,navigate])

  async function handleLogInResponse(res) {
    console.log("Encode JWT ID token : ", res.credential)
    const userObj = jwt_decode(res.credential)

     const response = await StudentService.studentLogin({email: userObj.email, password: userObj.email + userObj.email })
    if (response.data.status === 200) {
        login(response.data.authtoken,"student")
        navigate("/student/blogs");
      } else {
        setErrorLogin(response.data.error_message||"Login failed!");
      }
  }

  // useEffect(() => {
  //    google?.accounts.id.initialize({
  //     client_id: '1041474059417-hfquknf7h9rlucg9t1knperkd9j50spr.apps.googleusercontent.com',
  //     callback: handleLogInResponse
  //   });

  //   google?.accounts.id.renderButton(
  //     document.getElementById('signInDiv'),
  //     { theme: 'outline', size: 'large' }
  //   );
  //   //   google.accounts.id.prompt();
  // }, [])

  const handleLogin = async (e) => {
    const response = await StudentService.studentLogin({email, password})
    if (response.data.status === 200) {
        login(response.data.authtoken,"student")
        navigate("/student/blogs");
      } else {
        setErrorLogin(response.data.error_message||"Login failed!");
      }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  }

  return (
    <div className='student-login-page user-form'>
      <div className='box-style1-fit'>
        <form>
          <div className='student-login-form-content flex-column gap-1 justify-center align-center'>
            <div className='font-size-large-title text-color'>User Login</div>     
              <div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={(e)=>setEmail(e.target.value)}
                  placeholder="Enter Your Email"
                  required
                />
              </div>
              <div>
                <input
                  type="password"
                  id="password"
                  name="password"
                  onChange={(e)=>setPassword(e.target.value)}
                  placeholder="Password"
                  onKeyPress={handleKeyPress}
                  required
                />
              </div>
              <div className='button button-style1' onClick={handleLogin}>Log In</div>
              {errorLogin && <div>{errorLogin}</div>}
              <Link to='/forgot-password'><div>Forgot your password?</div></Link>
              {/* <div>OR</div> */}
              <div id='signInDiv'></div>
              <div>Not a Member yet? <span><Link to='/student/signup'>Sign Up here</Link></span></div>           
          </div>
        </form>
      </div>
    </div> 
  )
}

export default  StudentLogin;
