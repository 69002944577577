import axios from "axios";

// get backend endpoint
const backend_endpoint = process.env.REACT_APP_BACKEND_ENDPOINT;


const createStudent = async (param) => {
  
    const requestBody = JSON.stringify(param);
    try {
      const response = await axios.post(`${backend_endpoint}/student/create-account`, requestBody, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const status = response.data.status
      if (status === 200) {
        return response
      } else if (status === 400) {
        return response
      }
    } catch (error) {
      console.error('Error:', error.message); 
    }
  };

const updateStudent = async (param) => {

  const requestBody = JSON.stringify(param);
  try {
    const response = await axios.post(`${backend_endpoint}/student/update-account`, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error.message);
  }
}

const getStudentInfo = async () => {
  try {
    const response = await axios.get(`${backend_endpoint}/student/get-student`, {
      headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
  }
   
}

//the response should have user_id
//param : { email: email, password:password}
//response : {success : true, user_id: ...}
const studentLogin = async (param) => {
  const response = await axios.post(`${backend_endpoint}/student/login`, param, {
    headers: {
      'Content-Type': 'application/json'
    },
  });
  return response
}


// use this as reference for all api services for front end, if we want to catch error, catch here
// also change api to : fetch-all-jobs
const getAllJobs = async () => {
  try {
    const res = await axios.get(`${backend_endpoint}/company/fetchalljobs`, {
      headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
      },
    });
    return res;
  } catch (error) {
    console.error('Error fetching jobs:', error);
  }
};

//TODO : searchJob api, return the same data as fetchAllJob
// res : { success : true, data : []}
const searchJob = async (keyword, location) => {
  try {
    const res = await axios.post(`${backend_endpoint}/company/search-job`, {
      headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
      },
      body: {
        keyword: keyword,
        location: location,
      }
    });
    return res.data;
  } catch (error) {
    console.error('Error searching job:', error);
    return [];
  }
};

//TODO : this sendApplication
//res : {success: true}
const sendApplication = async (resume, message, posting_id,user_id) => {
    const res = await axios.post(`${backend_endpoint}/send-application`, {
      headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
      },
      body: {
        resume: resume,
        message: message,
        user_id : user_id,
        posting_id : posting_id,
      }
    }).catch ((error) => {
    console.error('Error applying job:', error)
    return {success: false}} )
  return res
};

const uploadResumeAndReturnUrl = async (file) => {
  try {
      const response = await fetch(backend_endpoint + '/resume/upload', {
        method: 'POST',
        body: file,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        const data = await response.json();
        alert(`File uploaded. Link: ${data.link}`);
      }
    } catch (error) {
      console.error('Error uploading file: ', error);
    }
}


export const  StudentService = {
  createStudent,
  updateStudent,
  studentLogin,
  getAllJobs,
  searchJob,
  sendApplication,
  getStudentInfo,
  uploadResumeAndReturnUrl
};