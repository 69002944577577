import React , {useState} from 'react';
import logoIcon from '../../../../img/logo-color.svg'
import "./JobCard.css"
import Modal from 'react-modal';
import { useAuth } from '../../../common/AuthContext';
import { StudentService } from '../../../../services/student-services';

const JobCard = ({posting,skills}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [message,setMessage] = useState(null);
  const [resume,setResume] = useState();
  const {userId} = useAuth()
  const [errorMessage,setErrorMessage] = useState(null)

  const handleApply = async () => {
    const posting_id = posting.posting_id
    if (resume) {
      setErrorMessage(null)
      const res = await StudentService.sendApplication(resume, message,posting_id,userId)
      if (res.success) {
        setErrorMessage(null)
        closeModal()
      } else {
        setErrorMessage('Failed to apply')
      }
    } else {
      setErrorMessage('Please attach your resume')
    }
  };

  const openModal = () => {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className='job-card-component'>
      <div className='job-card-content box-style1' >
        <div className='section1'>
          <div className='flex-row gap-1 left-div'>
            <div className='employer-logo'>
            <img src={logoIcon} alt="Company Logo" style={{ height: '30px' }} className="img-fluid rounded-circle" />
            </div>
            <div>
              <div className='font-size-small-title text-color'>{posting.position}</div>
              <div className='text-bold'>{posting.employer}</div>
              <div>{posting.location.city}, {posting.location.province}</div>
            </div>
          </div>
          <div>
            <div>{posting.level} - {posting.type}</div>
            <div>Salary Range: {posting.monthly_salary_range_start} - {posting.monthly_salary_range_end} /month</div>
            <div>{posting.status} until {posting.close_at}</div>
          </div>
        </div>

        <div className='section2'>
          <div>Description</div>
          <div>{posting.description}</div>
        </div>

        <div className='section3'>
          {skills.map((item,index)=>
          <div className='badge-style1' key={index}>{item}</div>)}
        </div>

        <div className='apply-button button button-style1' onClick={openModal}>Apply Now</div>
      </div>

        <Modal
          isOpen= {modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Job Application"
          className='modal-style1 job-application-modal'
        >
        <div className='m-header'>
          <div>Apply to {posting.employer}</div>
          <div className='icon-button button-style3' onClick={closeModal}>x</div>     
        </div>
        <div className='m-body'> 
        <div className='content'>
          <div className='upload-resume'>
            <div>Your resume</div>
            <input type="file" onChange={(e)=>setResume(e.target.files[0])}/>
          </div>
          <div className='flex-column gap-half'>
            <div>Message to employer</div>
            <div>
              <textarea value={message} onChange={(e)=>setMessage(e.target.value)}/>
            </div>
          </div>
        </div>
         
          
        </div>
        <div className='m-footer'>
            <div className='button button-style1' onClick={handleApply}>Apply</div>
            <div className='button button-style2' onClick={closeModal}>Cancel</div>
        </div>
        {errorMessage && <div>{errorMessage}</div>}
      </Modal>
    </div>
    

  
  );
};

export default JobCard;
