import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = ({children }) => {
   const { isLoggedIn, userType} = useAuth();
   console.log("isLoggedIn : " ,isLoggedIn)
  return isLoggedIn ? children : <Navigate to="/" />;
};

export default PrivateRoute;