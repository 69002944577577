import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CompanyJobList = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const editModalRef = useRef(null); // Ref for the edit modal

  const ref = useRef(null)
  const refClose = useRef(null)

  let navigate = useNavigate();

  // get backend endpoint
  const backend_endpoint = process.env.REACT_APP_BACKEND_ENDPOINT;

  // Fetching the job when page is loaded
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate("/company-login");
      return;
    }
    const fetchData = async () => {
      try {
        const response = await fetch(backend_endpoint + '/company/fetchjobs', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'auth-token': localStorage.getItem('token')
          },
        });
        const data = await response.json();
        setJobs(data);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchData();
  }, []);

  // Custom function to show the modal
  const showModal = () => {
    const modalElement = editModalRef.current;
    if (modalElement) {
      modalElement.classList.add('show');
      modalElement.style.display = 'block';
      document.body.classList.add('modal-open');
    }
  };

  // Custom function to hide the modal
  const hideModal = () => {
    const modalElement = editModalRef.current;
    if (modalElement) {
      modalElement.classList.remove('show');
      modalElement.style.display = 'none';
      document.body.classList.remove('modal-open');
    }
  };

  // Select the job and open the modal
  const handleEdit = (job) => {
    setSelectedJob(job);
    showModal();
  };

  // Close the modal
  const handleCloseEditModal = () => {
    hideModal(); // Hide the edit modal using our custom function
  };

  // API call for editing the Job
  const handleSaveChanges = async () => {
    try {
      // Ensure that the selectedJob exists before attempting to update
      if (!selectedJob) {
        console.log('error')
        return;
      }

      // Prepare the data to be sent in the request body
      const { title, description, stipend, location } = selectedJob;
      const updatedJobData = {
        title,
        description,
        stipend,
        loc: location, // Assuming your backend expects "loc" instead of "location"
      };

      // Send the PUT request to update the job
      const response = await fetch(backend_endpoint + `/company/updatejob/${selectedJob._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': localStorage.getItem('token'), // Include the authorization token if required by your backend
        },
        body: JSON.stringify(updatedJobData),
      });

      if (response.ok) {
        const updatedJob = await response.json();
        // Update the job in the jobs state with the updated data
        setJobs((prevJobs) =>
          prevJobs.map((job) => (job._id === updatedJob._id ? updatedJob : job))
        );

        // Close the modal after successful update
        hideModal();
        console.log('Job updated successfully');
      } else {
        console.log('Failed to update job');
      }
    } catch (error) {
      console.error('Error updating job:', error);
    }
  };

  // Delete the job
  const handleDelete = async (jobId) => {
    console.log(`Delete job with ID ${jobId}`);
    try {
      // API Call
      const response = await fetch(backend_endpoint + `/company/deletejob/${jobId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': localStorage.getItem('token')
        },
      });

      if (response.ok) {
        // If the delete operation is successful, update the state to remove the deleted job
        const updatedJobs = jobs.filter((job) => job._id !== jobId);
        setJobs(updatedJobs);
        console.log('Job deleted successfully');
      } else {
        console.log('Failed to delete job');
      }
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };


  //   return (
  //     <div className="container">
  //       <h1 className='text-center'>Job List</h1>
  //       <div className="row">
  //         {jobs.map((job) => (
  //           <div className="col-md-4 mb-4" key={job.id}>
  //             <div className="card">
  //               <div className="card-body">
  //                 <h5 className="card-title">{job.title}</h5>
  //                 <div className='d-flex'>
  //                   <p className="card-text"><b>Stipend : </b>{job.stipend}$ </p>
  //                   <p className="card-text mx-4"><b>Location : </b>{job.location} </p>
  //                 </div>
  //                 <p className="card-text">{job.description}</p>
  //                 {/* <p className="card-text">{job._id}</p> */}
  //                 <div className="d-flex justify-content-between">
  //                   <button
  //                     className="btn btn-primary"
  //                     onClick={() => handleEdit(job)}
  //                   >
  //                     Edit
  //                   </button>
  //                   <button
  //                     className="btn btn-danger"
  //                     onClick={() => handleDelete(job._id)}
  //                   >
  //                     Delete
  //                   </button>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         ))}
  //       </div>


  //  {/* Edit Job Modal */}
  //  {selectedJob && (
  //         <div
  //           className={`modal fade ${showEditModal ? 'show' : ''}`}
  //           id="exampleModal"
  //           tabIndex="-1"
  //           role="dialog"
  //           aria-labelledby="exampleModalLabel"
  //           aria-hidden={!showEditModal}
  //         >
  //           <div className="modal-dialog" role="document">
  //             <div className="modal-content">
  //               <div className="modal-header">
  //                 <h5 className="modal-title" id="exampleModalLabel">
  //                   Edit Job
  //                 </h5>
  //                 <button
  //                   type="button"
  //                   className="close"
  //                   data-dismiss="modal"
  //                   aria-label="Close"
  //                   onClick={handleCloseEditModal}
  //                 >
  //                   <span aria-hidden="true">&times;</span>
  //                 </button>
  //               </div>
  //               <div className="modal-body">
  //                 <div className="form-group">
  //                   <label htmlFor="title">Title</label>
  //                   <input
  //                     type="text"
  //                     className="form-control"
  //                     id="title"
  //                     value={selectedJob.title}
  //                     onChange={(e) =>
  //                       setSelectedJob({ ...selectedJob, title: e.target.value })
  //                     }
  //                   />
  //                 </div>
  //                 <div className="form-group">
  //                   <label htmlFor="description">Description</label>
  //                   <textarea
  //                     className="form-control"
  //                     id="description"
  //                     value={selectedJob.description}
  //                     onChange={(e) =>
  //                       setSelectedJob({ ...selectedJob, description: e.target.value })
  //                     }
  //                   />
  //                 </div>
  //               </div>
  //               <div className="modal-footer">
  //                 <button
  //                   type="button"
  //                   className="btn btn-secondary"
  //                   onClick={handleCloseEditModal}
  //                 >
  //                   Close
  //                 </button>
  //                 <button type="button" className="btn btn-primary" onClick={handleSaveChanges}>
  //                   Save changes
  //                 </button>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       )}
  //     </div>


  //   );

  return (
    <div className="container">
      <h1 className="text-center">Job List</h1>
      <div className="row">
        {jobs.map((job) => (
          <div className="col-md-4 mb-4" key={job.id}>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{job.title}</h5>
                <div className="d-flex">
                  <p className="card-text">
                    <b>Stipend : </b>
                    {job.stipend}$
                  </p>
                  <p className="card-text mx-4">
                    <b>Location : </b>
                    {job.location}
                  </p>
                </div>
                <p className="card-text">{job.description}</p>
                <div className="d-flex justify-content-between">
                  <button className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" ref={ref} onClick={() => handleEdit(job)}>
                    Edit
                  </button>
                  <button className="btn btn-danger" onClick={() => handleDelete(job._id)}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Edit Job Modal */}
      <div
        className="modal"
        tabIndex="-1"
        role="dialog"
        ref={editModalRef}
        style={{ display: 'none' }}
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Job</h5>
              <button type="button" className="close" onClick={handleCloseEditModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  value={selectedJob?.title}
                  onChange={(e) => setSelectedJob({ ...selectedJob, title: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="title">Location</label>
                <input
                  type="text"
                  className="form-control"
                  id="location"
                  value={selectedJob?.location}
                  onChange={(e) => setSelectedJob({ ...selectedJob, location: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="title">Stipend</label>
                <input
                  type="text"
                  className="form-control"
                  id="stipend"
                  value={selectedJob?.stipend}
                  onChange={(e) => setSelectedJob({ ...selectedJob, stipend: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                  className="form-control"
                  id="description"
                  value={selectedJob?.description}
                  onChange={(e) => setSelectedJob({ ...selectedJob, description: e.target.value })}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleCloseEditModal}>
                Close
              </button>
              <button type="button" className="btn btn-primary" onClick={handleSaveChanges}>
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default CompanyJobList;
